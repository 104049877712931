import React, { useEffect, useState } from "react";
import Layout from "layouts/DashboardLayout.jsx";
import { connect } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";

import {
  // LockScreen,
  Login,
  Register,
  ForgotPassword
} from "../views/pages/index";
import { compose } from "redux";
import authActions from "redux/auth/actions";
import navigationAction from "redux/navigation/actions";
import Loader from "components/Loader";
import ResetPassword from "views/pages/authentication/ResetPassword";
import GroupChat from "views/pages/chats/groupChat";
import PersonalChat from "views/pages/chats/personalChat";
import MyChats from "views/pages/chats/MyChats";
import Intro from "views/pages/home/Intro";
import AdminPanel from "views/pages/admin/AdminPanel";
import AdminLocation from "views/pages/admin/AdminCategory";

const { check, logout, setUser } = authActions;

const Dashboard = React.lazy(() => import("../views/pages/home/home"));

const Profile = React.lazy(() =>
  // import("../views/pages/authentication/profile")
  import("../views/pages/authentication/profile")
);
const UserList = React.lazy(() => import("../views/pages/chats/userList"));
const UserProfie = React.lazy(() => import("../views/pages/users/userProfile"));

const ReactRoutes = props => {
  console.log(props);
  const { check, user_id, token, isLogin } = props;
  // const token = true
  // console.log(check,token)
  const [isLoading, setIsLoading] = useState(true);

  return !isLoading ? (
    <Loader />
  ) : (
    <>
      <Routes>
        {/* <Route
          path="/"
          element={!token ? <Navigate to="login" /> : <Layout />}
          null
        > */}
        <Route path="/" element={<Layout />} null>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loader />}>
                <Dashboard />
              </React.Suspense>
            }
          />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/admin/categories" element={<AdminLocation />} />
          <Route
            path="profile"
            element={
              !token ? (
                <Navigate to="/intro" />
              ) : (
                <React.Suspense fallback={<Loader />}>
                  <Profile />
                </React.Suspense>
              )
            }
          />
          {/* <Route
            path="my_chats"
            element={!token ? <Navigate to='/login'/> :
              (<React.Suspense fallback={<Loader />}>
                <MyChats />
              </React.Suspense>)
            }
          /> */}
          <Route
            path="my_chats"
            element={token ? <MyChats /> : <Navigate to="/login" />}
          >
            <Route path="chat/:id" element={<GroupChat />} />
            <Route path="recipient/:id" element={<PersonalChat />} />
          </Route>
          <Route
            path="chat/:id"
            element={
              <React.Suspense fallback={<Loader />}>
                <GroupChat />
              </React.Suspense>
            }
          />
          <Route
            path="recipient/:id"
            element={
              <React.Suspense fallback={<Loader />}>
                <PersonalChat />
              </React.Suspense>
            }
          />
          <Route
            path="userlist/:id"
            element={
              <React.Suspense fallback={<Loader />}>
                <UserList />
              </React.Suspense>
            }
          />

          <Route
            path="userProfile/:id"
            element={
              <React.Suspense fallback={<Loader />}>
                <UserProfie />
              </React.Suspense>
            }
          />
        </Route>
        <Route path="/intro" element={<Intro />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* <Route
          path="/lockscreen"
          element={token ? <Navigate to="/" /> : <LockScreen />}
        /> */}
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
      </Routes>
    </>
  );
};

// const mapStateToProps = state => ({
//   token: state.auth.accessToken?.token,
//   user: state.auth.user,
//   user_id: state.auth.user_id
// });

const mapStateToProps = state => {
  console.log(state.auth.isLogin);
  return {
    token: state.auth.accessToken,
    user: state.auth.user,
    isLogin: state.auth.isLogin
    // user_id: state.auth.user.uid,
  };
};

export default compose(
  connect(mapStateToProps, {
    check,
    logout,
    setUser
  })
)(ReactRoutes);
