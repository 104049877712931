import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AuthActions from "redux/auth/actions";
import { compose } from "redux";
import {
  getAllEvents,
  addUserToGroup,
  getEvents
} from "services/dashboardServices";
import "./home2.css";
import LocationIcon from "./LocationIcon";
import DropDownIcon from "./DropDownIcon";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchAllCategories, fetchAllLocations } from "services/adminServices";

const { success, error, updateCredits } = AuthActions;

const Home = props => {
  const { token, userInfo, role } = props;
  console.log(token, userInfo, role);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allEvents, setAllEvents] = useState([]);
  const [userEvents, setUserEvents] = useState([]);
  const [city, setCity] = useState("New York"); // Default city
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [expandedEvents, setExpandedEvents] = useState({});
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (role === "admin") {
      navigate("/admin");
      return;
    }

    let isMounted = true;

    const getAllUserEvents = async () => {
      try {
        let data;
        if (token) {
          data = await getAllEvents(token);
        } else {
          data = await getEvents();
        }
        if (isMounted) {
          if (data.success) {
            setAllEvents(data.data);
            setUserEvents(data.userEvents?.map(e => e.eventId.toString()));
          } else {
            dispatch(error("Something Went Wrong"));
          }
        }
      } catch (error) {
        console.error("Error fetching events:", error);
        dispatch(error("Something Went Wrong"));
      }
    };

    const fetchLocations = async () => {
      try {
        const data = await fetchAllLocations(token);
        if (data.success) {
          setLocations(data.data.map(location => location.name));
        }
      } catch (err) {
        console.error("Error fetching locations:", err);
      }
    };

    const fetchCategories = async () => {
      try {
        const data = await fetchAllCategories();
        if (data.success) {
          setCategories(data.data.map(category => category?.name));
        }
      } catch (err) {
        console.error("Error fetching categories:", err);
      }
    };
    fetchCategories();
    fetchLocations();
    getAllUserEvents();

    return () => {
      isMounted = false;
    };
  }, [token, dispatch]);

  const openModal = event => {
    if (!token) {
      navigate("/intro");
    } else {
      addUser(event);
    }
  };

  const addUser = async event => {
    if (!event) return;

    const addObj = {
      userId: userInfo.userId,
      groupId: event._id
    };
    const data = await addUserToGroup(token, addObj);
    if (data.success) {
      dispatch(updateCredits(data.credits));
      navigate(`/chat/${event._id}`);
    } else {
      dispatch(error(data.message));
      console.log("Failed to add user");
    }
  };

  const handleCityChange = selectedCity => {
    setCity(selectedCity);
    setIsDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredEvents = allEvents.filter(event => {
    return event.location === city;
  });

  const categorizeEvents = () => {
    const categorized = {};
    categories.forEach(category => {
      categorized[category] = [];
    });
    filteredEvents.forEach(event => {
      if (event.status === "up" && categorized[event?.category?.name]) {
        categorized[event?.category?.name].push(event);
      }
    });
    return categorized;
  };

  const truncateDescription = (description, isExpanded) => {
    if (isExpanded) {
      return description;
    }
    const words = description.split(" ");
    if (words.length > 10) {
      return words.slice(0, 10).join(" ") + "...";
    }
    return description;
  };

  const toggleExpand = eventId => {
    setExpandedEvents(prevState => ({
      ...prevState,
      [eventId]: !prevState[eventId]
    }));
  };

  const settings = {
    className: "slider",
    dots: true,
    appendDots: dots => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: i => <div className="custom-dot" />,
    dotClass: "slick-dots custom-top-home",
    infinite: false,
    speed: 500,
    slidesToShow: 1.7,
    swipeToSlide: true,
    slidesToScroll: 1,
    waitForAnimate: false
  };

  return (
    <div className="home-container">
      <div className="home-title">Join a group & plan a meetup!</div>
      <header className="header">
        <div>
          <LocationIcon />
        </div>
        <div className="dropdown" ref={dropdownRef}>
          <div className="dropdown-header" onClick={handleDropdownToggle}>
            {city}
            <DropDownIcon />
          </div>
          {isDropdownOpen && (
            <div className="dropdown-menu">
              {locations.map((loc, index) => (
                <div
                  key={index}
                  className="dropdown-item"
                  onClick={() => handleCityChange(loc)}
                >
                  {loc}
                </div>
              ))}
            </div>
          )}
        </div>
      </header>
      <div className="events-container">
        {Object.entries(categorizeEvents()).map(
          ([category, events]) =>
            events.length > 0 && (
              <div className="category" key={category}>
                <h2 className="category-txt">{category}</h2>
                <Slider {...settings}>
                  {events.map(
                    e =>
                      e.status === "up" && (
                        <div key={e._id} className="eventss-cards-container">
                          <div className="events-cards">
                            <img
                              src={
                                e?.image?.url
                                  ? e?.image?.url
                                  : "/changes/no_event.png"
                              }
                              className="events-cards-img"
                              alt={e.name}
                            />
                            <div className="events-title">
                              <h5 className="events-card-title">{e.name}</h5>
                              <p className="events-card-text">
                                {truncateDescription(
                                  e.description,
                                  expandedEvents[e._id]
                                )}
                                {e.description.split(" ").length > 10 && (
                                  <span
                                    onClick={() => toggleExpand(e._id)}
                                    className="read-more-link"
                                  >
                                    {expandedEvents[e._id]
                                      ? " Show Less"
                                      : " Read More"}
                                  </span>
                                )}
                              </p>
                              <div className="button-container">
                                <button
                                  onClick={() => {
                                    userEvents?.includes(e._id)
                                      ? navigate(`/chat/${e._id}`)
                                      : openModal(e);
                                  }}
                                  className="button"
                                  style={{
                                    borderRadius: "30px",
                                    margin: "0 auto !important"
                                  }}
                                >
                                  Join Group
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </Slider>
              </div>
            )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.accessToken,
    userInfo: state.auth.user,
    // credits: state.auth.credits,
    role: state.auth.user.role
  };
};

export default compose(
  connect(mapStateToProps, { success, error, updateCredits })
)(Home);
