import React, { memo, useEffect, useState, useRef, useCallback } from "react";
import { IconButton, Stack, Typography, TextField } from "@mui/material";
import { Send } from "@mui/icons-material";
import {
  addGroupChatMessage,
  addRecipientChatMessage,
  getEventAllMessages,
  getRecipientAllMessages
} from "services/dashboardServices";
import MessageComponent from "./messageComponent";
import io from "socket.io-client";
import AuthActions from "redux/auth/actions";
import ScrollableFeed from "react-scrollable-feed";
import "./chatView.css";
import LockScreenOverlay from "./LockScreenOverlay";

const { success, error } = AuthActions;
// const SOCKET_SERVER_URL = "http://localhost:3001";
// const SOCKET_SERVER_URL = "https://test.bandlr.com";
const SOCKET_SERVER_URL = "https://bandlr.com";
let socket;

const generateChatRoomId = (userId1, userId2) =>
  [userId1, userId2].sort().join("_");

const ChatViewComponent = ({ user, groupId, isPersonal }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [replyTo, setReplyTo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasSentFirstMessage, setHasSentFirstMessage] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const messagesEndRef = useRef(null);

  const chatRoomId = isPersonal
    ? generateChatRoomId(user.userId, groupId)
    : groupId;

  const getChatMessage = async () => {
    try {
      setLoading(true);
      let data;
      if (!isPersonal) {
        data = await getEventAllMessages(user.token, groupId, page);
      } else {
        data = await getRecipientAllMessages(user.token, groupId, page);
      }
      if (Array.isArray(data.data)) {
        setMessages(prevMessages => [...prevMessages, ...data.data]);
        setHasMore(data.hasMore !== undefined ? data.hasMore : false);
        const userHasSentMessages = data.data.some(
          msg => msg.userId === user.userId
        );
        setHasSentFirstMessage(userHasSentMessages);
      } else {
        setMessages([]);
        setHasMore(false);
      }
      if (!isPersonal) {
        socket.emit("join chat", groupId);
      } else {
        socket.emit("join personalChat", user.userId, groupId);
      }
    } catch (err) {
      console.error("Error fetching messages:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getChatMessage();
  }, [groupId, page]);

  useEffect(() => {
    setMessages([]);
    setPage(1);
    setHasMore(true);
    setHasSentFirstMessage(false);
  }, [groupId]);

  useEffect(() => {
    socket = io(SOCKET_SERVER_URL);
    socket.emit("setup", { user, groupId: chatRoomId });
    socket.on("connected", () => console.log("Socket connected"));
    socket.on("message received", handleNewMessage);
    socket.on("personalMessage received", handleNewPersonalMessage);
    return () => {
      socket.off("connected");
      socket.off("message received", handleNewMessage);
      socket.off("personalMessage received", handleNewPersonalMessage);
      socket.disconnect();
    };
  }, [groupId]);

  const handleNewMessage = useCallback(
    newMessage => {
      if (newMessage.eventId === groupId) {
        setMessages(prevMessages => [...prevMessages, newMessage]);
        scrollToBottom();
      }
    },
    [groupId]
  );

  const handleNewPersonalMessage = useCallback(
    data => {
      if (data.room === chatRoomId) {
        setMessages(prevMessages => [...prevMessages, data.newMessage]);
        scrollToBottom();
      }
    },
    [chatRoomId]
  );

  useEffect(() => {
    scrollToBottom();
  }, []);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end"
      });
    }
  };

  const submitHandler = async e => {
    e.preventDefault();
    if (!message) return;

    console.log(replyTo);
    const data = {
      userId: user.userId,
      groupId: groupId,
      message: message,
      profileImage: user.profileImage,
      userInfo: {
        _id: user.userId,
        name: user.name
      },
      replyTo: replyTo
        ? {
            message: replyTo.message,
            userInfo: replyTo.userInfo,
            msgId: replyTo.msgId,
            userId: replyTo.userId
          }
        : null
    };

    try {
      let response;
      if (!isPersonal) {
        // console.log(data)
        response = await addGroupChatMessage(user.token, data);
        socket.emit("new message", response.data);
      } else {
        response = await addRecipientChatMessage(user.token, data);
        socket.emit("new personalMessage", response.data);
      }
      // console.log(response)
      setMessage("");
      setReplyTo(null);
      setHasSentFirstMessage(true);
      scrollToBottom();
    } catch (err) {
      console.error("Error sending message:", err);
    }
  };

  const handleMessage = e => {
    setMessage(e.target.value);
  };

  const handleReply = message => {
    setReplyTo(message);
  };

  const handleScroll = () => {
    if (
      messagesEndRef.current &&
      messagesEndRef.current.getBoundingClientRect().top <= window.innerHeight
    ) {
      if (!loading && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, hasMore]);

  return (
    <div
      className="chat-view-container"
      style={{
        minHeight: "20vh",
        display: "flex",
        flexDirection: "column",
        padding: "0 10px",
        height: "88%"
      }}
    >
      <div
        style={{
          flex: 1,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {loading && messages.length === 0 ? (
          <></>
        ) : messages.length ? (
          <ScrollableFeed>
            <div
              style={{
                flex: 1,
                overflowY: "auto",
                display: "flex",
                flexDirection: "column"
              }}
            >
              {messages.map((e, index) => (
                <MessageComponent
                  key={index}
                  user={user}
                  message={e}
                  onReply={handleReply}
                />
              ))}
            </div>
          </ScrollableFeed>
        ) : (
          <Typography
            variant="body1"
            align="center"
            style={{ paddingTop: "2rem" }}
          >
            No messages yet. Start the conversation!
          </Typography>
        )}
        <div ref={messagesEndRef} />
      </div>
      {replyTo && (
        <div
          style={{
            padding: "1rem",
            borderTop: "1px solid #ccc",
            backgroundColor: "#f1f1f1",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Typography variant="body2" color="textSecondary">
            Replying to {replyTo.userInfo.name}: {replyTo.message}
          </Typography>
          <button
            onClick={() => setReplyTo(null)}
            style={{ border: "none", background: "none", color: "#007bff" }}
          >
            Cancel
          </button>
        </div>
      )}
      <form
        style={{ padding: "1rem", borderTop: "1px solid #ccc" }}
        onSubmit={submitHandler}
      >
        <Stack
          direction="row"
          height="100%"
          alignItems="center"
          justifyContent="space-between"
          position="relative"
        >
          {/* <InputBox
            style={{ height: "50px", flex: 1, marginRight: "10px" }}
            placeholder={hasSentFirstMessage ? "Type a message" : "Introduce yourself"}
            value={message}
            onChange={handleMessage}
          /> */}
          <TextField
            className="custom-textfield"
            style={{
              flex: 1,
              marginRight: "10px",
              backgroundColor: "#0000000D",
              border: "none",
              boxShadow: "none",
              // border:"2px solid black",
              outline: "none"
              // overflow:"hidden",
              // borderRadius: "55px",
            }}
            placeholder={
              hasSentFirstMessage ? "Type a message" : "Introduce yourself"
            }
            value={message}
            onChange={handleMessage}
            multiline
            maxRows={4}
            variant="outlined"
            // variant="filled"
          />
          <IconButton
            sx={{
              backgroundColor: "black",
              color: "white",
              padding: "0.5rem",
              "&:hover": { bgcolor: "error.dark" }
            }}
            type="submit"
          >
            <Send />
          </IconButton>
        </Stack>
      </form>

      {!user.profileImage?.url && (
        // <LockScreenOverlay onProfileImageUpload={handleProfileImageUpload} />
        <LockScreenOverlay />
      )}
    </div>
  );
};

export default memo(ChatViewComponent);
