import { api, handleResponse, handleError } from "./apiServices";

export const getAllEvents = token =>
  api(token)
    .get("/api/home/events")
    .then(handleResponse)
    .catch(handleError);

export const getEvents = () =>
  api()
    .get("/api/home/all_events")
    .then(handleResponse)
    .catch(handleError);

export const getAllMyChats = token =>
  api(token)
    .get("/api/home/allmychats")
    .then(handleResponse)
    .catch(handleError);

export const getEventDetails = (token, id) =>
  api(token)
    .get(`/api/home/event/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const getRecipientDetails = (token, id) =>
  api(token)
    .get(`/api/home/recipient/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const addUserToGroup = (token, body) =>
  api(token)
    .post("/api/home/addUserToGroup", body)
    .then(handleResponse)
    .catch(handleError);

// new api call
export const addGroupChatMessage = (token, body) =>
  api(token)
    .post("/api/home/addGroupChatMessage", body)
    .then(handleResponse)
    .catch(handleError);

export const addRecipientChatMessage = (token, body) =>
  api(token)
    .post("/api/home/addRecipientChatMessage", body)
    .then(handleResponse)
    .catch(handleError);

export const getUserListByEvent = (token, id) =>
  api(token)
    .get(`/api/home/eventUserList/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const getFriendProfile = (token, id) =>
  api(token)
    .get(`/api/home/getUserProfile/${id}`)
    .then(handleResponse)
    .catch(handleError);

// export const getEventAllMessages = (token, id) =>
//   api(token)
//     .get(`/api/home/getEventMessage/${id}`)
//     .then(handleResponse)
//     .catch(handleError);

export const getEventAllMessages = (token, id, page = 1, limit = 20) =>
  api(token)
    .get(`/api/home/getEventMessage/${id}?page=${page}&limit=${limit}`)
    .then(handleResponse)
    .catch(handleError);

export const getRecipientAllMessages = (token, id, page = 1, limit = 20) =>
  api(token)
    .get(`/api/home/getRecipientMessage/${id}?page=${page}&limit=${limit}`)
    .then(handleResponse)
    .catch(handleError);

export const getNotification = token =>
  api(token)
    .get("/admin/api/getNotification")
    .then(handleResponse)
    .catch(handleError);

export const totalNoOfStation = token =>
  api(token)
    .get("admin/dashboard/totalNoOfStation")
    .then(handleResponse)
    .catch(handleError);

export const getAllDetails = token =>
  api(token)
    .get("/api/taxi/dashboard/getAllDashboard")
    .then(handleResponse)
    .catch(handleError);

export const getRecentInquiryUser = token =>
  api(token)
    .get(`/api/taxi/dashboard/getRecentInquiryUser`)
    .then(handleResponse)
    .catch(handleError);

export const getTotalInquiryChat = (token, date) =>
  api(token)
    .get(`/api/taxi/dashboard/getTotalInquiryChat?year=${date}`)
    .then(handleResponse)
    .catch(handleError);
