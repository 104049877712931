import axios from "axios";

const BACKEND_URI = process.env.REACT_APP_BACKEND_URI
  ? process.env.REACT_APP_BACKEND_URI
  : "";

export const api = token => {
  console.log("api-service-token : " + token);
  if (typeof token === "string" && token.split(".").length === 3)
    return axios.create({
      // withCredentials: true,
      baseURL: `${BACKEND_URI}/`,
      headers: { Authorization: `Bearer ${token}` }
    });
  else
    return axios.create({
      // withCredentials: true,
      baseURL: `${BACKEND_URI}/`
    });
};

export const handleResponse = res => {
  try {
    const data = res.data;
    console.log(data);
    if (res.data.error) {
      const error = data.message ? data.message : data.error;
      return Promise.reject(error);
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

// export const handleError = err => {
//   console.log("err.response.status", err.response.status);
//   if (err.response.status === 401) {
//     localStorage.setItem("isLogin", false);
//     localStorage.setItem("accessToken", null);
//     document.cookie = document.cookie = `token= ;SameSite=strict;max-age=0}`;
//     if (window.location.pathname !== "/login") {
//       window.location = "/login";
//     }
//   }
//   return err.response.data;
// };

export const handleError = err => {
  if (err.response && err.response.status === 401) {
    localStorage.setItem("isLogin", false);
    localStorage.setItem("accessToken", null);
    document.cookie = `token= ;SameSite=strict;max-age=0`;
    console.log("i");
    // return;
    if (window.location.pathname !== "/login") {
      window.location = "/login";
    }
  } else if (err.response) {
    return err.response.data;
  } else if (err.request) {
    console.error("Request error:", err.request);
    return Promise.reject("Request error: No response received");
  } else {
    console.error("Unexpected error:", err.message);
    return Promise.reject("Unexpected error occurred");
  }
};
