import React, { useRef, useState } from "react";
import { Typography, Button, Avatar, CircularProgress } from "@mui/material";
import { Lock } from "@mui/icons-material";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import { updateProfilePic } from "services/authServices";
// import AuthActions from "../../../redux/auth/actions";
import AuthActions from "../../redux/auth/actions";
import imageCompression from "browser-image-compression";

const { success, error, setUser, login } = AuthActions;

const LockScreenOverlay = ({ userId, token, user }) => {
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  //   console.log(userId)
  //   console.log(token)
  console.log(user.name);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(file);

      setUploading(true);
      console.log(`Original File size: ${file.size / 1024 / 1024} MB`);
      const options = {
        maxSizeMB: 0.5, // Smaller file size for faster uploads
        maxWidthOrHeight: 1280, // Lower dimensions for images typically viewed on mobile devices
        useWebWorker: true,
        quality: 0.75, // Reduce quality for further size reduction
        resizeMethod: "browser-high-quality",
        exifOrientation: true,
        onProgress: function(progress) {
          console.log("Compression Progress: ", progress, "%");
        },
      };
      try {
        const compressedFile = await imageCompression(file, options);
        console.log(
            `Compressed File size: ${compressedFile.size / 1024 / 1024} MB`
          );
        console.log(file);
        const formData = new FormData();
        formData.append("profileImage", compressedFile);
        // formData.append("public_id",profileImageInfo.public_id)
        formData.append("userId", userId);
        formData.append("name",user.name)

        const data = await updateProfilePic(token, formData);
        if (data.success) {
          success(data.message);
          console.log(data.user)
          let newData = {
            ...user,
            profileImage: data.user.profileImage
          }
          dispatch(setUser(data.user))
        //   dispatch(login(token))
        //   dispatch(login(result.data.token));
        }
      } catch (error) {
        error(error.message);
        console.error("Error uploading image:", error);
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backdropFilter: "blur(2px)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10,
        flexDirection: "column",
        color: "#fff",
        textAlign: "center",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <Lock style={{ fontSize: 80, marginBottom: "20px" }} />
      <Typography variant="h6">Access Restricted</Typography>
      <Typography variant="body1" style={{ marginTop: "10px" }}>
      For the safety of our users, we require everyone to upload their profile picture
      </Typography>

      {preview ? (
        <Avatar
          src={preview}
          alt="Profile Preview"
          sx={{ width: 100, height: 100, marginTop: "20px" }}
        />
      ) : (
        <Avatar
          sx={{
            width: 100,
            height: 100,
            marginTop: "20px",
            backgroundColor: "gray",
          }}
        />
      )}

      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "20px" }}
        onClick={handleUploadClick}
        disabled={uploading}
      >
        {uploading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Upload Profile Picture"
        )}
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleFileChange}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.user.userId,
    token: state.auth.accessToken,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { success, error, setUser, login })(LockScreenOverlay);
